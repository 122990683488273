var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"displayCustomisation"}},[_c('div',{staticClass:"ui inverted dimmer",class:{ active: _vm.loading }},[_c('div',{staticClass:"ui loader"})]),(_vm.project && _vm.feature_type)?_c('h1',[_vm._v(" Modifier l'affichage sur la carte des signalements de type \""+_vm._s(_vm.feature_type.title)+"\" pour le projet \""+_vm._s(_vm.project.title)+"\" ")]):_vm._e(),_c('div',{attrs:{"id":"symbology"}},[_c('h3',[_vm._v("Symbologie")]),_c('form',{staticClass:"ui form",attrs:{"id":"form-symbology-edit","action":"","method":"post","enctype":"multipart/form-data"}},[(_vm.feature_type)?_c('SymbologySelector',{attrs:{"id":"default","init-color":_vm.feature_type.color,"init-icon":_vm.feature_type.icon,"init-opacity":_vm.feature_type.opacity,"geom-type":_vm.feature_type.geom_type},on:{"set":_vm.setDefaultStyle}}):_vm._e(),(_vm.customizableFields.length > 0)?_c('div',{staticClass:"fields inline"},[_c('label',{attrs:{"id":"customfield-select-label","for":"customfield-select"}},[_vm._v(" Champ de personnalisation de la symbologie: ")]),_c('div',{attrs:{"id":"custom_types-dropdown"}},[_c('Dropdown',{attrs:{"options":_vm.customizableFields,"selected":_vm.selectedCustomfield,"selection":_vm.selectedCustomfield,"clearable":true},on:{"update:selection":function($event){_vm.selectedCustomfield=$event}}})],1)]):_vm._e(),(_vm.selectedCustomfield)?_c('div',{staticClass:"field",attrs:{"id":"customFieldSymbology"}},_vm._l((_vm.selectedFieldOptions),function(option){return _c('SymbologySelector',{key:option,attrs:{"id":option,"title":option,"init-color":_vm.feature_type.colors_style.value ?
            _vm.feature_type.colors_style.value.colors[option] ?
              _vm.feature_type.colors_style.value.colors[option].value :
              _vm.feature_type.colors_style.value.colors[option]
            : null,"init-icon":_vm.feature_type.colors_style.value ?
            _vm.feature_type.colors_style.value.icons[option] :
            null,"init-opacity":_vm.getOpacity(_vm.feature_type, option),"geom-type":_vm.feature_type.geom_type},on:{"set":_vm.setColorsStyle}})}),1):_vm._e()],1)]),_c('div',{staticClass:"ui divider"}),(_vm.feature_type && _vm.feature_type.customfield_set)?_c('div',{attrs:{"id":"popupDisplay"}},[_c('h3',[_vm._v("Prévisualisation des champs personnalisés de l'info-bulle")]),_c('table',{staticClass:"ui definition single line compact table",attrs:{"id":"table-fields-to-display","aria-describedby":"Liste des champs à afficher"}},[_vm._m(0),_c('tbody',_vm._l((_vm.featureAnyFields),function(field){return _c('tr',{key:field.name,class:{ first_customfield: _vm.feature_type.customfield_set[0] &&
            field.name === _vm.feature_type.customfield_set[0].name }},[_c('td',{staticClass:"collapsing center aligned",attrs:{"scope":"row"}},[_c('div',{staticClass:"ui toggle checkbox"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.form.displayed_fields.includes(field.name)},on:{"input":function($event){return _vm.toggleDisplay($event, field.name)}}}),_c('label')])]),_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(field.name)+" ("+_vm._s(field.label)+") ")]),_c('td',{attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(field.field_type || _vm.getCustomFieldType(field.field_type))+" ")])])}),0)])]):_vm._e(),_c('button',{staticClass:"ui teal icon button margin-25",attrs:{"id":"save-display","type":"button","disabled":!_vm.canSaveDisplayConfig},on:{"click":_vm.sendDisplayConfig}},[_c('i',{staticClass:"white save icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Sauvegarder l'affichage du type de signalement ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Prévisualisation du champ ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Champ ")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Type ")])])])}]

export { render, staticRenderFns }